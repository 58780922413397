.header {
  background: url(../../Assets/Hero/Background.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 120vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

@media screen and (max-width: 769px) {
  .header {
    height: auto;
  }
}
