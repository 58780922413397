.input {
  border-radius: 15px !important;
  padding: 10px 15px !important;
}

.del:hover {
  cursor: pointer !important;
  color: black !important;
}
.check {
  cursor: pointer !important;
  color: green !important;
  margin-right: 5px !important;
}
.check:hover {
  cursor: pointer !important;
  color: black !important;
}
