.chart_icon {
  color: var(--primary);
  font-size: 20px;
  transition: 0.3s all ease-in-out;
  margin: 0 10px;
}
.logo {
  position: absolute;
  top: 0px;
  left: 0;
  width: 90px;
  border-radius: 50%;
}
.logo2 {
  position: absolute;
  top: 0px;
  right: 0;
  width: 90px !important;
  height: 90px !important;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
}

.chart_icon:hover {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.line:nth-child(odd) {
  height: 100%;
  width: 4px;
  background: var(--primary);
}
.line:nth-child(3n + 2) {
  background: transparent !important;
}

/* .bordr_right {
  border-right: 1px solid var(--primary);
} */

.info_heading {
  margin-right: 12px;
  white-space: nowrap !important;
}
.info_text {
  white-space: nowrap;
}
.overall_info {
  font-size: 14px !important;
}

.img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.img img {
  width: 90px;
  max-height: 90px;
}

.std_info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr !important;
  gap: 10px;
}
.std_overview {
  display: grid;
  grid-template-columns: 1fr !important;
  /* grid-template-columns: 220px 1fr 1fr !important; */
  gap: 10px;
}
.std_overview_inner {
  display: grid;
  grid-template-columns: 1fr !important;
  /* grid-template-columns: 220px 1fr 1fr !important; */
  gap: 10px;
}

.wrapper {
  font-size: 14px !important;
}

.act_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 10px;
  padding-top: 10px;
}
.act {
  text-align: center;
}
@page {
  margin: 20px 20px 20px 20px !important;
}

@media print {
  .overall_info {
    font-size: 10px !important;
  }
  .wrap {
    font-size: 12px !important;
  }
}
